export const projects = [
  {
    title: "Sneaker Store",
    description:
      "A basic design for a shoe store. Users can view sneakers and search for them via a search bar, and sort based on price. User can also post their own sneakers for sale and add to the cart to purchase with Stripe API",
    image: "/images/sneakerstore.png",
    tags: [
      "React",
      "Ruby on Rails",
      "Google Maps API",
      "Semantic UI",
      "PostgreSQL",
      "React-Router",
    ],
    source: "https://github.com/joshrs1199/sneakerappfrontend",
    source2: "https://github.com/joshrs1199/sneakerappbackend",
    visit: "https://www.youtube.com/watch?v=k1XcuJ-1P1c&feature=youtu.be",
    id: 1,
  },

  {
    title: "Dollar Pizza Finder",
    description:
      "Using the google maps api a user is able to view dollar pizzas nearby. A user is able to add a new location as well as read/write reviews on that location.",
    image: "/images/dollarpizza.png",
    tags: [
      "React",
      "Ruby on Rails",
      "Google Maps API",
      "Material UI",
      "PostgreSQL",
    ],
    source: "https://github.com/JohnAlexanderPena/backendpizza",
    source2: "https://github.com/JohnAlexanderPena/FrontEndPizza",
    visit: "https://youtu.be/hpIcT3D1bpc",
    id: 0,
  },

  {
    title: "Meal Prep Website",
    description:
      "Designed to help a user keep track of their nutritional intakes. A user can enter a food and using the USDA API they can gather nutritional information on that item and save it to their journal. A user can opt-in to buy a mealprep package. Able to update meal prep subscriptions. A user can also view individual meals and purchase them using the Stripe API.",
    image: "/images/mealprep.png",
    tags: [
      "React",
      "Ruby on Rails",
      "Redux",
      "Semantic UI",
      "CSS",
      "JWT Authentication",
      "USDA API",
      "React-Router",
    ],
    source: "https://github.com/JohnAlexanderPena/MealPrepFrontEnd",
    source2: "https://github.com/JohnAlexanderPena/MealPrepBackEnd",
    visit: "https://youtu.be/gUMiDT_P1do",
    id: 2,
  },
  {
    title: "Watch Finder",
    description:
      "Built a web scraper to get information to use a database. Able to search through hundreds of watches, rendering different watch brands, models, and sub-models.",
    image: "/images/watches.png",
    tags: ["React", "Ruby on Rails", "Redux", "Bootstrap", "CSS"],
    source: "https://github.com/JohnAlexanderPena/watch-collection-frontend",
    source2: "https://github.com/JohnAlexanderPena/watch-collection-backend",
    visit: "https://youtu.be/LSyhnQ3UYW4",
    id: 3,
  },
  {
    title: "Social Network App",
    description:
      "Create/Edit/Delete a full profile about yourself. As a non-user you can view all profiles. As a registered user you can post to webapp. You can like/unlike posts left by other people. Used Github API to render latest repos(limited to 5).",
    image: "/images/networkingapp.png",
    tags: [
      "React",
      "Node",
      "MongoDB",
      "JWT Authentication",
      "Express",
      "Redux",
      "Bootstrap",
      "GithubAPI",
    ],
    visit: "https://www.youtube.com/watch?v=9sX1s4ygsD8&feature=youtu.be",
    source: "https://github.com/JohnAlexanderPena/developers-networking-app",
    id: 4,
  },

  {
    title: "Amazon Clone",
    description:
      "User is able to create a new account and/or login using Firebase Auth. User can view items and add them to cart and save to the database for later purchases. User can remove items from cart and checkout using Stripe API. As a logged in user, you can view previous orders.",
    image: "/images/amazon.png",
    tags: [
      "React",
      "Redux",
      "React Hooks",
      "Firebase",
      "MongoDB",
      "Firebase Authentication",
      "Firebase Database",
      "Bootstrap",
      "CSS",
    ],
    visit: "https://v3-f5de1.web.app/",
    source: "https://github.com/JohnAlexanderPena/amzon-clone",
    id: 5,
  },

  {
    title: "Instagram Clone",
    description:
      "View users profiles and photos. As a non-user you can view all profiles.Signup view Firebase authentication. You can like/unlike posts left by other people. You can follow/unfollow users. You can follow/unfollow users.",
    image: "/images/Insta.png",
    tags: [
      "React",
      "Redux",
      "React Hooks",
      "Firebase",
      "Firebase Authentication",
      "Firebase Database",
      "CSS Tailwind",
      "React Skeleton",
    ],
    visit: "https://ig-clone-9928f.web.app/login",
    source: "https://github.com/JohnAlexanderPena/IG-Clone",
    id: 6,
  },
];

export const TimeLineData = [
  { year: 2018, text: "Began exploring basic coding" },
  { year: 2019, text: "Joined a coding bootcamp" },
  { year: 2019, text: "Got my first professional job" },
  { year: 2020, text: "Created projects to gain more experience" },
  {
    year: 2021,
    text: "Continue my journey to explore the bigger world of software developement",
  },
];
