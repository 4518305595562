import React from "react";
import "./App.css";
import Nav from "./Nav";
// import Skills from "./Skills";
// import Projects from "./Projects";
import Projects from "./components/Projects/Projects";

import ContactInfo from "./Contact";
import pdf from "./resume/JohnPenaResume.pdf";
import Experience from "./Experience";
import Technologies from "./components/Technologies/Technologies";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import BgAnimation from "./components/BackgrooundAnimation/BackgroundAnimation";
import Timeline from "./components/TimeLine/TimeLine";
import { Section } from "./styles/GlobalComponents";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      {/* <header id="header">
        <div className="center text-center">
          <h1 className="bigheadline">John Peña</h1>
          <h4 className="subheadline">Software Engineer</h4>
        </div>
        <h3>
          <i className="pe-7s-portfolio pe-dj pe-va" />
          <span>
            <a style={{ color: "#e66f69", cursor: "pointer" }} href={pdf}>
              View Resume
            </a>
          </span>
        </h3>
        <div className="bottom">
          <a
            data-scroll
            href="#navigation"
            className="scrollDown animated pulse"
            id="scrollToContent"
          >
            <i className="pe-7s-angle-down-circle pe-va"></i>
          </a>
        </div>
      </header> */}
      <Header />
      <Section grid>
        <Hero />
        <BgAnimation />
      </Section>
      {/* <Nav /> */}
      {/* <Skills /> */}
      <Technologies />
      {/* <Projects /> */}
      <Projects />
      <Timeline />
      {/* <Experience /> */}
      {/* <ContactInfo /> */}
      <Footer />
    </div>
  );
}

export default App;
